import React from 'react'
import Layout from 'layouts/en'
import { JourneyState } from './index.tw'
import { Languages } from 'i18n/config'

import 'styles/base.less'

import i18n from 'i18n/en/i18n_journey.json'

const Journey: React.FC = (props) => {
  const lang = Languages.EN
  const demoUrl = `/form/parade/`
  const paradeUrl = ''

  return (
    <Layout {...props}>
      <JourneyState demoUrl={demoUrl} paradeUrl={paradeUrl} lang={lang} i18n={i18n} />
    </Layout>
  )
}

export default Journey
